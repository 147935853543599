.scaninst-main {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(../../asset/sep09\ 1.png);
}

.scaninst-textmain {
  height: calc(100% - 165px);
  overflow: auto;
  margin-top: 0px;
}

.scaninst-main .fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #d9d9d9;
  padding: 15px;
  margin: 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.scaninst-h1 {
  text-align: center;
  font-size: 20px;
  margin-top: -5px;
}

.scaninst-fdiv1 {
  display: flex;
  border: 2px solid black;
  padding: 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  height: 48px;
}

.scaninst-box1 {
  background-color: #000;
  width: 47px;
  height: 47px;
  margin-right: 10px;
  left: -27px;
  top: -1px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo1 {
  position: absolute;
  background: black;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  left: 5px;
  top: 0px;
  padding: 5px;
}

.scaninst-fsdiv {
  text-align: left;
  margin-left: 22px;
}

.scaninst-fdiv2h1 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(34, 34, 34, 1);
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 10px;
  text-transform: none;
  position: relative;
  margin: -3px 0px 5px 0;
}

.scaninst-fdiv2p {
  white-space: pre-wrap;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  font-style: none;
  letter-spacing: 0px;
  line-height: 18px;
  text-transform: none;
  margin: 0px 0px 8px 0;
}

.scaninst-fdiv2 {
  display: flex;
  border: 2px solid black;
  padding: 10px 10px 0px 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
}

.scaninst-fdiv3 {
  display: flex;
  border: 2px solid black;
  padding: 10px 10px 0px 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
}

.scaninst-fdiv4 {
  display: flex;
  border: 2px solid black;
  padding: 10px 10px 0px 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
}

.scaninst-fdiv5 {
  display: flex;
  border: 2px solid black;
  padding: 10px 10px 0px 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
}

.scaninst-fdiv6 {
  display: flex;
  border: 2px solid black;
  padding: 10px 10px 0px 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
}

.scaninst-fdiv7 {
  display: flex;
  border: 2px solid black;
  padding: 10px;
  width: 80%;
  margin-left: 13%;
  border-radius: 15px;
  position: relative;
  margin-top: 6px;
  height: 48px;
}

.scaninst-box2 {
  background-color: #000;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: -30px;
  top: 8px;
  border-radius: 50%;
  overflow: auto;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo2 {
  position: absolute;
  background: black;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: 0;
  top: 0;
  padding: 12px;
}

.scaninst-box3 {
  background-color: #000;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: -30px;
  top: 8px;
  border-radius: 50%;
  overflow: auto;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo3 {
  position: absolute;
  background: black;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: 0;
  top: 0;
  padding: 12px;
}

.scaninst-box7 {
  background-color: #000;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  left: -27px;
  top: 0px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo7 {
  position: absolute;
  background: black;
  width: 47px;
  height: 47px;
  margin-right: 10px;
  left: 0;
  top: -4px;
  padding: 12px;
}

.scaninst-box4 {
  background-color: #000;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: -30px;
  top: 8px;
  border-radius: 50%;
  overflow: auto;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo4 {
  position: absolute;
  background: black;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: 0;
  top: 0;
  padding: 12px;
}

.scaninst-image2 {
  display: flex;
  position: absolute;
  isolation: isolate;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 50px;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 15px;
  top: 200px;
}

.scaninst-image3 {
  display: flex;
  position: absolute;
  isolation: isolate;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 50px;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 15px;
  top: 306px;
}

.scaninst-image4 {
  display: flex;
  position: absolute;
  isolation: isolate;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 50px;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 15px;
  top: 306px;
}

.scaninst-image7 {
  display: flex;
  position: absolute;
  isolation: isolate;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 50px;
  width: 65px;
  height: 67px;
  position: absolute;
  left: 12px;
  top: 94%;
  object-fit: cover;
}

.scaninst-box5 {
  background-color: #000;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: -30px;
  top: 16px;
  border-radius: 50%;
  overflow: auto;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo5 {
  position: absolute;
  background: black;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: 0;
  top: 0;
  padding: 12px;
}

.scaninst-box6 {
  background-color: #000;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: -30px;
  top: 8px;
  border-radius: 50%;
  overflow: auto;
  z-index: 1;
  padding: 10px;
  position: absolute;
}

.scaninst-logo6 {
  position: absolute;
  background: black;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  left: 0;
  top: 0;
  padding: 12px;
}

.scaninst-btn {
  display: block;
  background-color: #000;
  color: #fff;
  border-color: #0000;
  height: 42px;
  width: 75%;
  border-radius: 10px;
  margin: 2px auto;
}

.scan-lastdiv {
  margin-top: 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
}

.scaninst-lastp {
  text-align: center;
  color: rgba(102, 102, 102, 1);
}

.scaninst-lasta {
  text-decoration: none;
  color: rgba(0, 0, 0, 1) !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .scaninst-box2 {
    top: 6px;
  }
  .scaninst-box3 {
    top: 8px;
  }
  .scaninst-box4 {
    top: 8px;
  }
  .scaninst-box5 {
    top: 10px;
  }
  .scaninst-box6 {
    top: 8px;
  }
}
