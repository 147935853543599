.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 10px;
  outline: 0;
  z-index: 9999;
  width: 65%;
}
button.custom-btn {
  border: none;
  background: none;
  color: #0069ff;
  font-size: 18px;
}
.custom-alert.error {
  font-size: 16px;
  font-weight: 500;
}
hr.custom-hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0.01px solid gray;
}

.success {
  background-color: #4caf50;
  color: #fff;
}

@font-face {
  font-family: "Wingdings";
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}

@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
  font-family: "Calibri";
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}

@font-face {
  font-family: ff1;
  panose-1: 0 0 0 0 0 0 0 0 0 0;
}

@font-face {
  font-family: ff2;
  panose-1: 0 0 0 0 0 0 0 0 0 0;
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 0cm;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpMiddle,
p.MsoListParagraphCxSpFirst,
p.MsoListParagraphCxSpLast {
  font-size: 14px !important;
  line-height: 22px !important;
  align-items: top;
}

p.MsoNormal {
  font-size: 14px !important;
  line-height: 22px !important;
}

a:link,
span.MsoHyperlink {
  color: #0563c1;
  text-decoration: underline;
}

p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

.MsoPapDefault {
  margin-bottom: 8pt;
  line-height: 107%;
}

@page WordSection1 {
  size: 612pt 792pt;
  margin: 72pt 72pt 72pt 72pt;
}

div.WordSection1 {
  page: WordSection1;
  margin-top: 15px;
  height: 55vh;
  overflow-y: scroll;
}

ol {
  margin-bottom: 0cm;
}

ul {
  margin-bottom: 0cm;
}

.sign-main {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}

.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signinlogo {
  height: 115px;
  margin-top: 100px;
}

.signin-h1 {
  text-align: center;
  margin: 50px;
  font-size: 35px;
  font-weight: bold;
}

.sign-btn {
  display: block;
  background-color: #000;
  color: #fff;
  border-color: #0000;
  height: 54px;
  margin: 0 auto;
  width: 80%;
  border-radius: 10px;
  height: 45px;
  cursor: pointer;
}

p.signin-p {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  width: 78%;
  margin-left: 46px;
  margin-top: 6px;
}

.signin-a {
  color: rgba(0, 0, 0, 1) !important;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.signor-p {
  text-align: center;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
  margin-bottom: 40px;
  margin-top: 20px;
}

.sign-span {
  font-size: 20px;
  margin: 0 20px;
}

.sign-lastp {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  width: 78%;
  margin-left: 46px;
  margin-top: 6px;
}

.signup-a {
  color: rgba(0, 0, 0, 1) !important;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.popup-signcontent1 {
  background-color: rgba(217, 217, 217, 1);
  width: 100%;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.custom-signpopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 10px;
}

.terlogo {
  height: 90px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 35px;
  padding: 10px;
}

.pop-logo {
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
  background: currentColor;
  border-radius: 50%;
}

.signpop-h1 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 18px;
}

.pop-p1 {
  font-size: 16px;
  margin-bottom: 20px;
}

.pop-p2 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pop-h4 {
  font-size: 19px;
  margin: 30px 0px;
}

.pop-h6 {
  font-size: 18px;
}

.pop-p4 {
  font-size: 16px;
}

.pop-check {
  height: 20px;
  width: 21px;
  margin-right: 10px;
}

.center-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.center-label1 {
  display: flex;
  align-items: center;
}

.pop-check1 {
  height: 20px;
  width: 21px;
  margin-right: 10px;
}

.sin-div {
  margin-top: 10px;
  margin-left: 25%;
}

.signpop-i {
  float: right;
  font-size: 25px;
}

.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.left-content {
  position: relative;
  padding: 0 20px;
  width: unset !important;
}

.left-content::before,
.left-content::after {
  content: "";
  display: inline-block;
  width: 120px;
  height: 1px;
  background-color: rgba(102, 102, 102, 1);
  vertical-align: middle;
  margin: 0 5px;
}

.left-content::before {
  margin-left: 0;
}

.left-content::after {
  margin-right: 0;
}
