.startscaning-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(../../asset/sep09\ 1.png);
}
.startscaning-main .fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #d9d9d9;
  padding: 4px 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  margin: 15px;
}
.startscaning-h1 {
  text-align: center;
  font-size: 23px;
}
.starscanning-p {
  margin-top: 40px;
  font-weight: 500;
  text-align: center;
}

.startscaning-logomain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 25px auto;
}
.startscaning-item p {
  text-align: center;
}
.startscaning-item {
  background-color: #d9d9d9;
  padding: 20px 25px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.startscaning-item.selected {
  background: black;
}
.startscaning-logo1 {
  color: #9999;
  display: block;
  margin: 0 auto;
  width: 85%;
  height: auto;
  border: 1px;
}
.selected .startscaning-logo1 {
  filter: invert(1);
}
.startscaning-logo2 {
  color: #9999;
  width: 90%;
  height: auto;
  border: 1px;
}
.startscaning-btn {
  display: block;
  background-color: #000;
  color: #fff;
  border-color: #0000;
  height: 45px;
  width: 75%;
  border-radius: 10px;
  margin: 35px auto;
  cursor: pointer;
}

.startscaning-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}
.startscaning-content1 {
  background-color: rgba(217, 217, 217, 1);
  width: 100%;
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.pop-text {
  align-items: center;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 20px;
  text-transform: none;
  text-align: center;
}
span.pop-textspan4 {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.pop-textspan1 {
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 32px;
  text-transform: none;
}
.pop-textspan2 {
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 32px;
  text-transform: none;
}
.pop-textspan3 {
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 20px;
  text-transform: none;
}
.pop-button {
  display: flex;
  justify-content: center;
}
.pop-btn1 {
  background-color: #000;
  color: #fff;
  border-color: #0000;
  border-radius: 6px;
  cursor: pointer;
  height: 42px;
  padding: 8px 15px;
}
.pop-btn2 {
  background-color: #000;
  color: #fff;
  border-color: #0000;
  border-radius: 6px;
  cursor: pointer;
  height: 42px;
  padding: 8px 15px;
  margin-left: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .startscaning-content1 {
    width: 58%;
  }
}
