.custom-signuppopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 10px;
}
.popup-signupcontent1 {
  background-color: rgba(217, 217, 217, 1);
  width: 100%;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.signcenter-label1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: "Wingdings";
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}

@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
  font-family: "Calibri";
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}

@font-face {
  font-family: ff1;
  panose-1: 0 0 0 0 0 0 0 0 0 0;
}

@font-face {
  font-family: ff2;
  panose-1: 0 0 0 0 0 0 0 0 0 0;
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 0cm;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpMiddle,
p.MsoListParagraphCxSpFirst,
p.MsoListParagraphCxSpLast {
  font-size: 14px !important;
  line-height: 22px !important;
  align-items: top;
}

p.MsoNormal {
  font-size: 14px !important;
  line-height: 22px !important;
}

a:link,
span.MsoHyperlink {
  color: #0563c1;
  text-decoration: underline;
}

p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 36pt;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

.MsoPapDefault {
  margin-bottom: 8pt;
  line-height: 107%;
}

@page WordSection1 {
  size: 612pt 792pt;
  margin: 72pt 72pt 72pt 72pt;
}

div.WordSection1 {
  page: WordSection1;
  margin-top: 15px;
  height: 55vh;
  overflow-y: scroll;
}

/* List Definitions */
ol {
  margin-bottom: 0cm;
}

ul {
  margin-bottom: 0cm;
}

.signup-main {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.signup-div {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px 5px;
  backdrop-filter: blur(42px);
  border-radius: 20px;
  top: -165px;
}

.signup-input {
  height: 45px;
}
.signup-input1 {
  height: 45px;
}

.locklogo {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 23%;
  left: 52%;
}

.signup-span {
  height: 43px;
  margin-top: 19px;
  border-right: none !important;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 90%;
  margin-left: 5%;
}
.signup-i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 4%;
  left: 10%;
  font-size: 22px;
  color: #ffff;
}
.signup-lastp {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  top: -150px;
  font-size: 15px;
  width: 93%;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.signup-lasta {
  color: #000000 !important;
}

.fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #fff;
  padding: 15px;
  margin: 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.sinup-div {
  position: relative;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: -150px;
  font-size: 15px;
  width: 93%;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.center-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}
.signpop-check1 {
  height: 20px;
  width: 21px;
  margin-right: 10px;
}
.signuppop-i {
  float: right;
  font-size: 25px;
}
.signup-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  margin: 0 auto;
  width: 88%;
  border-radius: 10px;
}

.background_img_sec {
  background-image: url(../../asset/Rectangle\ 86.png);
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 15px;
}
.form_section {
  position: relative;
}
.lower_text_sec {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  text-align: center;
  margin-top: 110px;
}
.main-section {
  position: fixed;
  overflow: scroll;
}
.background_img_sec img.locklogo_img {
  text-align: center;
  margin: 50px auto 0px;
  display: block;
}
.background_img_sec h1 {
  text-align: center;
  color: white;
  margin-top: 65px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .signup-div {
    top: -250px;
  }
  .background_img_sec {
    height: 946px;
  }
  .signup-lastp {
    top: -225px;
  }
  .signup-h1 {
    font-size: 25px;
  }
  .signcenter-label1 {
    position: relative;
    top: -70px;
  }
}
