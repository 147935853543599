
/* i.fas.fa-arrow-left.shoesel-i {
    font-size: 19px;
    color: #9999;
    background-color: #D9D9D9;
    padding: 4px 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 10px 10px 8px 15px;
    padding: 15px;
} */
.scanbackground_img_sec i.fas.fa-arrow-left.shoesel-i {
    font-size: 19px;
    color: #9999;
    background-color: #D9D9D9;
    padding: 4px 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 10px 10px -15px 15px !important;
    padding: 15px;
}

.shoe-arrimg {
    color: #9999;
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: 10px;
    object-fit: cover;
}
.shoe-logo{
    height: 40px;
    width: 40px;
    padding: 2px;
}
.shoe-item2{
    padding-left: 20px;
}

.shoe-main{
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background-image: url(../../asset/sep09\ 1.png);
}
.shoe-logomain {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px;
    flex-wrap: wrap;
}
.shoe-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shoe-h1 {
    text-align: center;
    font-size: 23px;
}

.shoe-main .fa-solid.fa-arrow-left {
    font-size: 19px;
    color: #9999;
    background-color: #D9D9D9;
    padding: 4px 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px;
    padding: 15px;

}

.shoe-p {
    text-align: center;
    font-weight: 500;
    margin: 15px 0;
    font-size: 14px;
}

.shoe-logomore {
  height: 40px;
  width: 40px;
  padding: 2px;
}

.shoe-lastp {
    margin-top: 25px;
    margin-left: 5%;
    font-weight: 500;
}

.shoe-logomain2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0 auto;
}

.shoe-male {
    color: #9999;
    background-color: #D9D9D9;
    padding: 11px 10px;
    width: 63px;
    border-radius: 10%;
}

.shoe-female {
    color: #9999;
    background-color: #D9D9D9;
    padding: 11px 10px;
    width: 63px;
    border-radius: 10%;
    margin-left: 25px;
}

.shoe-item2 p {
    text-align: center;
}

.shoe-item2 .shoe-fe {
    margin-left: 25px;
}

.shoe-item p {
    text-align: center;
}

.shoe-btn {
    display: block;
    background-color: #000;
    color: #fff;
    border-color: #0000;
    height: 45px;
    margin: 10% auto 20% auto;
    width: 80%;
    border-radius: 10px;

}

.shoe-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.shoe-content {
    background-color: #fff;
    height: 65vh;
    width: 95%;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    overflow: scroll;
}

.popshoe-btn {
    display: block;
    background-color: #000;
    color: #fff;
    border-color: #0000;
    height: 45px;
    margin: 0 auto;
    width: 75%;
    border-radius: 10px;
    margin-bottom: 25px;
} 
.selected .shoe-logo {
  filter: invert(1);
  }
  .selected .shoe-arrimg{
    background-color: black;
  }
  .shoe-item2.selected img{
    background-color:black; 
  }

  @media (max-width: 320px) {
    .shoe-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shoe-content {
        background-color: #fff;
        height: 95vh;
        width: 98%;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: scroll;
    }
    .shoe-logomain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding: 0px 8px;
        flex-wrap: wrap;
    }
    .shoe-item p {
        text-align: center;
        font-size: 13px;
    }
  }
  @media (max-width: 320px) and (max-width: 480px){
    .shoe-main {
        padding: 20px 0px 50px 0px;
    }
    .shoe-content {
        background-color: #fff;
        height: 75vh;
        width: 95%;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: scroll;
    }
  }
  @media (max-width: 767px) {
    .shoe-content {
        background-color: #fff;
        height: 70vh;
        width: 95%;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: scroll;
    }
  }

 
  @media (min-width: 376px) and (max-width: 667px) {
    .shoe-content {
        background-color: #fff;
        height: 52vh;
        width: 95%;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: scroll;
    }
  }
  @media (min-width: 414px) and (max-width: 736px) {
    .shoe-content {
        background-color: #fff;
        height: 54vh;
        width: 95%;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: scroll;
    }
  }



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .shoe-content {
        height: 42vh;
    }
    .shoe-lastp {
        margin-left: 10%;
    }
    .shoe-logomain2 {
        width: 83%;
    }
}