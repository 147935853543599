@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

*{
   padding: 0;
   margin: 0;
   font-family: 'SF Pro Display' ;
   font-family: 'Poppins', sans-serif ;

}

body{
    font-family: 'SF Pro Display' !important;
}

.wel-main{
    background-image: url(../asset/sep09\ 1\ \(1\).png);
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    height:100vh ;
    overflow: scroll;
    padding: 0px 0px 15px 0px;
}
.wel-logo{
    height: 50vh;
    width: 100%;
}
.wel-h1{
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color:rgba(255, 255, 255, 1) ;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 0px;
    text-transform: none

}
.wel-p{
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color:rgba(255, 255, 255, 1) ;
    font-weight: 400;
    font-size: 18px;
    width: 90%;
    margin-left: 5%;
    letter-spacing: 0px;
    text-transform: none;
}
.wel-lastdiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.wel-i{
    font-size: 40px;
    color: #9999;
    background-color: #fff;
    padding: 4px 6px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}
.wel-sdiv{
    margin-top: 20px;
}
