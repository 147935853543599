.first-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-image: url(../../asset/sep09\ 1.png);
}

.first-logo {
  width: 85px;
}
.first-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-div1 {
  display: flex;
  align-items: center;
  margin-top: 40%;
}
.first-h1 {
  margin-left: 10px;
}

/* spinner */
.snakeloader {
  margin: 0 auto;
  display: block;
  margin-top: 40px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.spin-div {
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  border: 12px dotted black;
}
.spinner-border.spin-div {
  animation-duration: 2s;
}
