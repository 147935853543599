span#basic-addon1 img {
  width: 20px;
}
i.fas.fa-arrow-left.shoesel-i {
  font-size: 19px;
  color: #9999;
  background-color: #d9d9d9;
  padding: 4px 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px;
  padding: 15px;
}
.loginmain-section {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}
.loginbackground_img_sec {
  background-image: url(../../asset/Rectangle\ 86.png);
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 15px;
}
.loginform_section {
  position: relative;
}
.loginlower_text_sec {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  text-align: center;
  margin-top: 135px;
}
.login-div {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px 5px;
  backdrop-filter: blur(42px);
  border-radius: 20px;
  top: -165px;
}

.login-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  margin: 0 auto;
  width: 88%;
  border-radius: 10px;
}
.login-input {
  height: 45px;
}
.login-input1 {
  height: 45px;
}
.login-h1 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 12%;
  left: 52%;
  font-size: 22px;
  color: #ffff;
  text-align: center;
  width: 100%;
}
.login-span {
  height: 43px;
  margin-top: 19px;
  border-right: none !important;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 90%;
  margin-left: 5%;
}
.login-i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 4%;
  left: 10%;
  font-size: 22px;
  color: #ffff;
}
.login-lastp {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  top: -150px;
  font-size: 15px;
  width: 93%;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.login-lasta {
  color: #000000 !important;
}
.login-input2 {
  height: 20px;
  width: 21px;
  margin-right: 10px;
  margin-left: 20px;
  border-radius: 10px;
}
.login-label {
  color: #000000;
}
.login-div1 {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-for {
  margin-right: 20px;
  color: #000000 !important;
}
.locklogo {
  text-align: center;
  margin: 25px auto 0px;
  display: block;
}

.fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #fff;
  padding: 15px;
  margin: 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
/* Media Query for Tablets */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-div {
    top: -250px;
  }
  .loginbackground_img_sec {
    height: 946px;
  }
  .login-lastp {
    top: -225px;
  }
  .login-h1 {
    font-size: 25px;
  }
}
