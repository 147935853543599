.leftfoot-top {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  top: 0;
}

.leftfoot-bottom {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  bottom: 0;
}
.leftfoot-left {
  background: #000000;
  height: 30%;
  position: absolute;
  z-index: 9;
  right: 0;
}
.leftfoot-right {
  background: #000000;
  height: 30%;
  position: absolute;
  z-index: 9;
  left: 0;
}
/*  */
.spinner {
  text-align: center;
  color: white;
}

video.left-img2web {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100% !important;
  position: fixed;
  right: 0;
  left: 0;
}

.clicked-webcam {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1280px;
  height: 720px;
  padding: 0;
  object-fit: cover;
}

.left-lastdiv {
  position: relative;
  height: 15%;
}

.left-lastidiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-lasti {
  font-size: 40px;
  color: #9999;
  background-color: #fff;
  padding: 4px 6px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.leftform_section {
  position: relative;
}

.left-img1 {
  bottom: 30%;
  left: 0%;
  position: fixed;
  right: 0%;
  top: 38%;
  z-index: 9;
  margin: 0 auto;
}

.left-pdiv p {
  margin-bottom: 8px;
  font-size: 16px;
}

.left-img3 {
  margin: 0 auto;
  height: 75px;
  width: 75px;
  padding: 8px 8px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  top: 75%;
  right: 0;
  left: 0;
  z-index: 99;
}

.left-main {
  background-color: black;
  width: 100%;
  height: 100vh;
  padding: 0px 20px;
  overflow: hidden;
  position: fixed;
  z-index: 999;
}

.left-mainimg {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
}

.left-h1 {
  color: #fff;
  font-size: 20px;
}

.left-pdiv {
  position: fixed;
  z-index: 999;
  font-size: 17px;
  padding-top: 20px;
}

.left-p1 {
  color: #fff;
}

.left-p2 {
  color: #fff;
}

.left-p3 {
  color: #fff;
}

.left-p4 {
  color: #fff;
}

.left-imgdiv1 {
  position: relative;
  overflow: hidden;
}

.left-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}

.left-content1 {
  width: 100%;
  height: 100%;
}

.leftpop-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.successlogo {
  display: block;
  margin: 0 auto;
}

.leftpop-div {
  margin-top: 30px;
}

.leftpop-h1 {
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

.leftpop-p {
  text-align: center;
  font-size: 15px;
  color: #636060;
  width: 75%;
  margin-top: 0px;
}

.leftpop-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 45px;
  margin: 0 auto;
  width: 75%;
  border-radius: 10px;
  margin-top: 15px;
}


@media only screen and (max-width: 480px) {
  .left-pdiv p {
    font-size: 14px;
  }
}
