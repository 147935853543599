.col-pop {
  position: absolute;
  top: 75px;
  right: 0;
  cursor: pointer;
  padding: 10px;
}
.comp-popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.comp-content1 {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.comppop-main1 {
  padding: 20% 1% 30% 1%;
}
.coll-btn {
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  width: 75%;
  border-radius: 10px;
}

/*  */
.footcom1-p {
  width: 93%;
  margin-left: 6px;
  text-align: justify;
  color: black;
  font-style: italic;
}

.footcom-contentu {
  padding: 10px;
  margin-top: 15px;
}

.footcom-content11 {
  margin-top: 25px;
}

.footcompop-i {
  float: right;
  font-size: 25px;
}

.comp-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-image: url(../../asset/sep09\ 1.png);
}

.comp-content {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.comppop-main {
  padding: 30% 1% 30% 1%;
}

.compsuccesslogo {
  display: block;
  margin: 0 auto;
}

.comppop-div {
  margin-top: 30px;
}

.comppop-h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
}

.comppop-p {
  text-align: center;
  font-size: 18px;
  color: #636060;
  width: 85%;
  margin-left: 8%;
  margin-top: 15px;
}

.comppop-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  margin: 0 auto;
  width: 75%;
  border-radius: 10px;
  margin-top: 45px;
}

.comppop-btn1 {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  margin: 0 auto;
  width: 75%;
  border-radius: 10px;
  margin-top: 20px;
}

/* pop1  */
.footcom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.footcom-content {
  background-color: #d9d9d9;
  width: 92%;
  padding: 15px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.footcom-h1 {
  height: 45px;
  padding: 6px 0px;
  background-color: #1db954;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  width: 15%;
}

.footcom-div {
  display: flex;
  align-items: center;
}

.footcom1-h1 {
  margin-left: 15px;
}

.footcom-p {
  margin-top: 10px;
  font-size: 15px;
  color: #272626;
  text-align: justify;
}

.footcom-p1 {
  font-size: 15px;
  color: #272626;
  text-align: justify;
}

.footcom-a {
  color: #1155cc;
}

.footcom-div1 {
  margin-top: 10px;
}

.footcomlogo {
  width: 20%;
}

.footcom-a1 {
  color: #1155cc;
}

.footcom-p2 {
  font-size: 15px;
  color: #272626;
}

/* pop2 */
.footcom-popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.footcom-content1 {
  background-color: rgba(217, 217, 217, 1);
  width: 85%;
  padding: 15px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.footcom-logodiv {
  display: block;
  margin: 0 auto;
}

.pop2logo {
  height: 125px;
  display: block;
  margin: 0 auto;
  font-size: 35px;
}

.footcom-pop1div {
  display: block;
  margin: 25px auto;
}

.footcom2-h1 {
  text-align: center;
}

.footcom2-p {
  margin-top: 10px;
  font-size: 15px;
  color: #272626;
}

.footcom2-p1 {
  font-size: 15px;
  color: black;
  font-style: italic;
  margin-left: 6px;
}

.footcom-input {
  height: 45px;
}

.footcom-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 45px;
  margin: 0 auto;
  width: 95%;
  border-radius: 10px;
}

.footcom-inputdiv {
  width: 95%;
  margin-left: 5px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .comp-popup {
    padding: 20px 0px 50px 0px;
  }
}
