.footvideo-video video {
  pointer-events: none;
  touch-action: none;
}

.video-container .video-react .video-react-play-control,
.video-container .video-react .video-react-pause-control {
  display: none !important;
}

.footvideo-main {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.footvideo-main .fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #d9d9d9;
  padding: 15px;
  margin: 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.footvideo-h1 {
  text-align: center;
  font-size: 23px;
}

input.footvideo-input {
  display: block;
  width: 90%;
  margin: 10px auto;
  --track-color: #d9d9d9;
}
.footvideo-input::-webkit-slider-thumb {
  background: rgb(64, 63, 63);
}
.footvideo-input::-moz-range-thumb {
  background: #2222;
}
.footvideo-input::-ms-thumb {
  background: #2222;
}

/*  */
@media (max-width: 375px) {
  .video-container {
    height: 70vh;
  }
  .video-container .video-react .video-react-play-control,
  .video-container .video-react .video-react-pause-control {
    display: none !important;
  }
}
