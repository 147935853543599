span#basic-addon1 {
  width: 38px;
  color: #706c6c;
}

.resetmain-section {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}
.resetbackground_img_sec {
  background-image: url(../../asset/Rectangle\ 86.png);
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 15px;
}
.resetform_section {
  position: relative;
}
.respass-div {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px 5px;
  backdrop-filter: blur(42px);
  border-radius: 20px;
  top: -165px;
}
.resetlogo {
  text-align: center;
  margin: 140px auto 0px;
  display: block;
}

.respass-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 45px;
  margin: 0 auto;
  width: 88%;
  border-radius: 10px;
}

.respass-input {
  height: 45px;
}

.respass-input1 {
  height: 45px;
}

.respass-h1 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 12%;
  left: 52%;
  font-size: 22px;
  color: #ffff;
  text-align: center;
  width: 100%;
}

.respass-span {
  height: 43px;
  margin-top: 19px;
  border-right: none !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 90%;
  margin-left: 5%;
}

.respass-i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 4%;
  left: 10%;
  font-size: 22px;
  color: #ffff;
}

.respass-lastp {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 66%;
  left: 50%;
  font-size: 18px;
  width: 85%;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.respass-lasta {
  color: #000000;
}

.login-label {
  color: #000000;
}

.login-for {
  margin-right: 20px;
  color: #000000;
}

.forgot-p {
  text-align: center;
  color: #ffff;
  width: 90%;
  margin-left: 15px;
  margin-bottom: 20px;
  font-size: 16px;
}

/*  */

.resetcustom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}

.resetpopup-content {
  width: 100%;
  height: 100%;
}
.respop-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.rightlogo {
  display: block;
  margin: 0 auto;
}
.pop-div {
  margin-top: 30px;
}
.pop-div h1 {
  margin: 5px 0;
}
.pop-h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
}
.pop1-h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 45px;
  font-weight: 800;
  margin-top: 0;
}
.pop-p {
  text-align: center;
  font-size: 15px;
  color: #636060;
  width: 85%;
  margin-left: 8%;
  margin-top: 15px;
}

.pop-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 45px;
  margin: 0 auto;
  width: 85%;
  border-radius: 10px;
  margin-top: 15px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .respass-div {
    top: -250px;
  }
  .resetbackground_img_sec {
    height: 946px;
  }
  .respass-h1 {
    font-size: 25px;
  }
}
