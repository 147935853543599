.start-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.start-content {
    background-color: #fff;
    width: 90%;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.start-navbar1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #181717;
    color: #fff;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.start-navbar1 img {
    background-color: white;
}

.start-navdiv {
    display: flex;
}

.start_navlogo1 {
    width: 60px;
    border-radius: 101%;
    border: none;
    background: none;
}

.nav1-p1 {
    margin: 15px 10px;
    font-size: 20px;
    font-weight: bold;
}

.startimgdiv img {
    width: 90%;
    margin: 0 auto;
    display: block;
}

.start-btn {
    display: block;
    background-color: #000;
    color: #fff;
    border-color: #0000;
    height: 40px;
    width: 75%;
    border-radius: 10px;
    margin: 0px auto;
    margin-bottom: 28px;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
    .start-popup {
     padding: 35px;
    }

    .startimgdiv img {
        width: 75%;
        margin: 0 auto;
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .start-popup {
        padding: 35px;
       }

    .startimgdiv img {
        width: 60%;
        margin: 0 auto;
        display: block;
    }

    .start-btn {
        width: 50%;
    }
}