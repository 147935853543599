.load-main {
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  .load-div {
    display: flex;
    margin: 50% auto 25% auto;
}
.loadlogo {
    width: 60px;
    border-radius: 100%;
    border: none;
    margin-right: 10px;
    background: none;
}
.load-p {
    margin: 12px auto;
    font-size: 20px;
    font-weight: bold;
}
.spin-div{
    display: block;
    margin: 0 auto;
}

