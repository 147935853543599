.second-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.second-div1 {
  text-align: center;
}

.addOnClass{
  background-color: black;
}

.second-logo {
  transition: height 1s, width 1s; 
}


