.rightfoot-top {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  top: 0;
}

.rightfoot-bottom {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  bottom: 0;
}

.rightfoot-left {
  position: absolute;
  height: 30%;
  background: rgb(0, 0, 0);
  left: 0;
  z-index: 9;
}

.rightfoot-right {
  position: absolute;
  height: 30%;
  background: rgb(0, 0, 0);
  right: 0;
  z-index: 9;
}

video.right-img2web {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100% !important;
  position: fixed;
  right: 0;
  left: 0;
}
.clicked-webcamr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1280px;
  height: 720px;
  padding: 0;
  object-fit: cover;
}

.right-lastdiv {
  position: relative;
  margin-top: 50px;
  bottom: 15px;
  padding-bottom: 50px;
}

.right-lastidiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-lasti {
  font-size: 40px;
  color: #9999;
  background-color: #fff;
  padding: 4px 6px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.right-pdiv p {
  margin-bottom: 8px;
  font-size: 16px;
}

.right-main {
  background-color: black;
  width: 100%;
  height: 100vh;
  padding: 0px 20px;
  overflow: hidden;
  position: fixed;
  z-index: 999;
}

.right-h1 {
  color: #fff;
  font-size: 20px;
}

.right-pdiv {
  position: fixed;
  z-index: 999;
  font-size: 17px;
  padding-top: 20px;
}

.right-p1 {
  color: #fff;
}

.right-p2 {
  color: #fff;
}

.right-p3 {
  color: #fff;
}

.right-p4 {
  color: #fff;
}

.right-img1 {
  bottom: 30%;
  left: 0%;
  position: fixed;
  right: 0%;
  top: 38%;
  z-index: 9;
  margin: 0 auto;
  height: 32%;
  width: 48%;
}

.right-imgdiv1 {
  position: relative;
  overflow: hidden;
}

.right-img2 {
  position: absolute;
  top: 6.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 200px;
  width: 157x;
  padding: 10px;
}

.right-img3 {
  margin: 0 auto;
  height: 75px;
  width: 75px;
  padding: 8px 8px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  top: 75%;
  right: 0;
  left: 0;
  z-index: 99;
}

/*  */
.right-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}

.right-content {
  width: 100%;
  height: 100%;
}

.rightpop-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.rightsuccesslogo {
  display: block;
  margin: 0 auto;
}

.rightpop-div {
  margin-top: 30px;
}

.rightpop-h1 {
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

.rightpop-p {
  text-align: center;
  font-size: 15px;
  color: #636060;
  width: 75%;
  margin-top: 0px;
}

.rightpop-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 45px;
  margin: 0 auto;
  width: 75%;
  border-radius: 10px;
  margin-top: 15px;
}

/* error popup */
.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.errorlogo {
  height: 100px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 35px;
  padding: 14px;
}

.errorpop-content1 {
  background-color: rgba(217, 217, 217, 1);
  width: 92%;
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.errorpop-logo {
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.errorpop-btn {
  display: block;
  background-color: #000000;
  color: #fff;
  border-color: #000000;
  height: 50px;
  margin: 0 auto;
  width: 70%;
  border-radius: 10px;
}

.error-h1 {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 25px;
}

.error1-h1 {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

.error-p {
  font-size: 14px;
}
.success-loader {
  margin: 0 auto;
  display: block;
  margin-top: 40px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* success popup */
.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.successpop-content1 {
  background-color: rgba(217, 217, 217, 1);
  width: 92%;
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.rightsuccesspop {
  display: block;
  margin: 0 auto;
}
.success-h1 {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
}
.success1-h1 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.success-p {
  font-size: 15px;
}
