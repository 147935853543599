.shoeshopp-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.shoeshopp-content {
    background-color: #fff;
    width: 98%;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.shoeshopp-navbar1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #181717;
    color: #fff;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.shoeshopp_navlogo1 {
    width: 60px;
    border-radius: 101%;
    border: none;
    background: none;
}

.shoeshopp-p1 {
    color: #222;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
}

.shoeshopp-p2 {
    color: #666;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.shoeshopp-div2 {
    margin: 25px 15px;
    border-radius: 10px;
    border: 1px solid #515151;
    display: flex;
    align-items: center;
}

.shoeshopp-img2 {
    max-width: 65px;
    margin-right: 5px;
}

.shoeshopp-container2 {
    display: flex;
    flex-direction: column;
}

.shoeshopp-btn {
    display: block;
    margin: 0 auto;
    background-color: #181717;
    border: #181717;
    width: 80%;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 30px;
}

.shoeshopp_navlogo1 a {
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    font-weight: bold;
}

.shoeshopp-content p {
    margin-left: 10px;
    margin-top: 15px;
}

.shoeshopp-navbar1 img {
    background-color: white;
}

.nav1-p1 {
    margin: 7px auto;
    font-size: 20px;
    font-weight: bold;
}

.shoeshopp-container1 p {
    margin: 0;
}

.shoeshopp-container2 p {
    margin: 0;
}

.pro-lastdiv1 {
    display: block;
    margin: 0 auto;
}

.shoeshopp-div3 {
    margin: 25px 15px;
    border-radius: 10px;
    border: 1px solid #515151;
    display: flex;
    align-items: center;
}

.shoeshopp-img3 {
    max-width: 65px;
    margin-right: 5px;
}

.shoeshopp-container3 {
    display: flex;
    flex-direction: column;
}

.shoeshopp-container3 p {
    margin: 0;
}

.shoeshopp-navdiv {
    display: flex;
}

.shoeshopp-idiv {
    position: relative;
}
.shoeshopp-idiv .fa-circle-xmark{
    position: absolute;
    right: 5px;
    top: 7px;
    font-size: 20px;
}
.shoeshopp-i{
    color: #fff !important;
}

.shoeshopp-h1 {
    color: #222;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 92%;
    margin: 20px;
}

/*  */
.shoeshopp-imgdiv {
    background-color: #000;
    width: 55px;
    height: 55px;
    margin-right: 10px;
    left: -35px;
    top: 4px;
    border-radius: 50%;
    overflow: auto;
    z-index: 1;
    padding: 10px;
    position: absolute;
}
.shoeshopp-imgdiv1 {
    background-color: #000;
    width: 55px;
    height: 55px;
    margin-right: 10px;
    left: -35px;
    top: 16px;
    border-radius: 50%;
    overflow: auto;
    z-index: 1;
    padding: 10px;
    position: absolute;
}

.shoeshopp-img1 {
    position: absolute;
    background: black;
    width: 55px;
    height: 55px;
    margin-right: 10px;
    left: 0;
    top: 0;
    padding: 12px;
}

.shoeshopp-div1 {
    display: flex;
    border: 2px solid black;
    padding: 10px;
    width: 85%;
    margin-left: 11%;
    border-radius: 15px;
    position: relative;
    margin-top: 15px;
}


.shoeshopp-container1 {
    text-align: left;
    margin-left: 15px;
}
.-last{
    margin-bottom: 30px;
}