.startscan-main {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}
.startscan-main .fa-solid.fa-arrow-left {
  font-size: 19px;
  color: #9999;
  background-color: #d9d9d9;
  padding: 4px 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  margin: 15px;
}
.startscan-h1 {
  text-align: center;
  font-size: 23px;
}
.startscan-p {
  text-align: center;
  margin-top: 20px;
  font-size: 23px;
  font-weight: 500;
}
.startscan-btn {
  display: block;
  background-color: #000;
  color: #fff;
  border-color: #0000;
  height: 45px;
  width: 75%;
  border-radius: 10px;
  margin: 10px auto;
}
.startscan-btn1 {
  display: block;
  background-color: #000;
  color: #fff;
  border-color: #0000;
  height: 45px;
  width: 75%;
  border-radius: 10px;
  margin: 15px auto;
  margin-bottom: 40px;
}

.startscan-logo {
  margin-top: 10px;
  width: 100%;
  height: 54%;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .startscan-main {
    padding: 20px 0px 50px 0px;
  }
}
