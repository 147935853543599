.carousel-indicators [data-bs-target] {
  width: 10px;
  border: none;
  background-color: #9c9c9c;
  height: 10px;
  border-radius: 50px;
  margin-right: 10px;
  border-radius: 50px;
}
.carousel-indicators button.active {
  background-color: black;
  height: 10px;
  width: 50px;
  border-radius: 50px;
}
.instructions-sli1.active {
  background-color: black;
  height: 10px;
  width: 50px;
}
.instructions-sli1 {
  width: 15px;
  border: none;
  background-color: #9c9c9c;
  height: 10px;
  border-radius: 50px;
  margin-right: 10px;
}
.instructions-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 10px);
  overflow: scroll;
  background-image: url(../../asset/sep09\ 1.png);
}

.page-div1 {
  flex: 1;
}

.page-div2 {
  flex: 1;
}

.instructions-btn {
  display: block;
  margin: 0 auto;
  background-color: #181717;
  border: #181717;
  width: 90%;
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.instructions-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 35px;
}

.Step1img {
  width: 100%;
  max-height: 100%;
}
.instructions-h5 {
  font-size:24px;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}

.Step2img {
  width: 100%;
  max-height: 100%;
}
.page2-h5 {
  font-size: 24px;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.Step3img {
  width: 100%;
  max-height: 100%;
}
.page3-h5 {
  font-size: 24px;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.Step4img {
  width: 100%;
  max-height: 100%;
}
.page4-h5 {
  font-size: 24px;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.instructions-btn {
  display: block;
  margin: 0 auto;
  background-color: #181717;
  border: #181717;
  width: 80%;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 45px;
  cursor: pointer;
  margin-top: 20px;
}
